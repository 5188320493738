import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback, useEffect, type FC } from 'react';

import { ProductSelectionPageTitle } from 'productSelection/components/PageTitle';
import { ProductGallery } from 'productSelection/containers/ProductGallery';
import { Section } from 'productSelection/containers/Section';
import { usePromotionPRCs } from 'productSelection/hooks/usePromotionPRCs';
import type {
  DigitalProduct,
  Product,
  UpsellProduct,
} from 'productSelection/types/products';
import { BlackHeaderBar } from 'shared/components/BlackHeaderBar';
import { Cart } from 'shared/components/Cart';
import { Footer } from 'shared/components/Footer';
import type { CountryWithLocale } from 'shared/components/LocaleDropdown/LocaleDropdown';
import { Meta } from 'shared/components/Meta';
import {
  useNotifications,
  withNotifications,
} from 'shared/components/NotificationProvider';
import { useLoadLocalOrder } from 'shared/hooks/orders/useLoadLocalOrder';
import { useResetOrderState } from 'shared/hooks/orders/useResetOrderState';
import { usePromotions } from 'shared/hooks/promotions/usePromotions';
import usePageMerchantCountryProtection from 'shared/hooks/routeProtection/usePageMerchantCountryProtection';
import type {
  IColumnLayout,
  IFooterFields,
  IMetadataContentFields,
  IUiNotificationsFields,
  IUiProductSelectionPageFields,
} from 'shared/infra/contentful/contentful';
import type {
  SkuCodePurchaseLimit,
  SkuPriceData,
} from 'shared/infra/storefront/markets/prices';
import { areChannelsDifferent } from 'shared/services/channel';
import { attachSearchToUrl } from 'shared/services/Links';
import logger from 'shared/services/logger';
import { FlagConfig, isFlagEnabledInBrowser } from 'shared/services/optimizely';
import { retrieveLocalOrderInformation } from 'shared/services/OrderInformationService';
import { dispatchProductSelectionPageViewEvent } from 'shared/services/tracker/events';
import { useTypedSelector } from 'shared/store';
import type { CartContent } from 'shared/types/cart';
import type { CoreProduct } from 'shared/types/product';
import { getChannel } from 'shared/utils/channel-link';

import {
  StyledGrid,
  TransparentFeesAndFooter,
} from './ProductSelection.styles';
import { getProductCardContent } from './ProductSelectionService';

export interface Props {
  products?: Product[];
  upsellProducts: UpsellProduct[];
  digitalProducts: DigitalProduct[];
  marketProducts: CoreProduct[];
  cartContent?: CartContent;
  prices?: Record<string, SkuPriceData>;
  purchaseLimitsBySkuCodes: SkuCodePurchaseLimit[];
  pageContent?: IUiProductSelectionPageFields & { addToCartText?: string };
  notificationsContent: IUiNotificationsFields;
  countries?: CountryWithLocale[];
  marketCode: string;
  commerceLayerClient: string;
  metaReference: IMetadataContentFields;
  footerContent: IFooterFields;
  isDiscountPriceEnabled?: boolean;
  taxMessage: string;
  sections?: IColumnLayout[];
  defaultTaxRate: number;
  isLogoClickable: boolean;
  isTTPOIFlagEnabled: boolean;
  isVATReversalFlagEnabled: boolean;
}

const MAX_PRODUCTS_PER_LINE = 4;

export const ProductSelectionComponent: FC<Props> = ({
  products,
  upsellProducts,
  digitalProducts,
  marketProducts,
  prices,
  purchaseLimitsBySkuCodes,
  cartContent,
  pageContent,
  notificationsContent: { removeOutOfStockProductMessage },
  countries = [],
  marketCode,
  metaReference,
  footerContent,
  taxMessage,
  isDiscountPriceEnabled = true,
  sections,
  defaultTaxRate,
  isLogoClickable,
  isTTPOIFlagEnabled,
  isVATReversalFlagEnabled,
}) => {
  usePageMerchantCountryProtection();

  const { locale, replace, pathname, query } = useRouter();
  usePromotions({ locale, marketCode });
  const loadLocalOrder = useLoadLocalOrder();
  const resetOrder = useResetOrderState();
  const channel = getChannel();
  const searchParams = useSearchParams();

  const productCardContent = getProductCardContent(pageContent);
  const {
    products: productsInCart,
    operationStatus,
    isBusinessAccountProposed,
  } = useTypedSelector((state) => state.order);
  const { displayNotification } = useNotifications();

  const isBusinessAccountBundleEnabled = isFlagEnabledInBrowser(
    FlagConfig.BA_BUNDLE,
  );

  const displayCountrySelectionNotification = useCallback(() => {
    displayNotification({
      title: pageContent.updateProductsNotificationTitle,
      message: pageContent.updateProductsNotificationDescription,
      variant: 'confirm',
      sendToTop: true,
    });
  }, [displayNotification, pageContent]);

  useEffect(() => {
    void dispatchProductSelectionPageViewEvent();
  }, []);

  useEffect(() => {
    if (
      operationStatus.showNotification &&
      operationStatus.type === 'removeOutOfStockProduct'
    ) {
      displayNotification({
        message: removeOutOfStockProductMessage ?? '',
        variant: 'notify',
        sendToTop: true,
      });
    }
  }, [
    displayNotification,
    operationStatus.showNotification,
    operationStatus.type,
    removeOutOfStockProductMessage,
  ]);

  const handleLocaleChange = async (code: string): Promise<void> => {
    await replace({ pathname, query }, undefined, { locale: code });
  };

  useEffect(() => {
    const { switched_country: switchedCountry, ...clearedQuery } = query;
    const localOrderInfo = retrieveLocalOrderInformation();

    const isAccountLocaleMismatch = Boolean(switchedCountry);
    const isLocalOrderLocaleMismatch = Boolean(
      localOrderInfo?.orderLocale && localOrderInfo?.orderLocale !== locale,
    );

    if (isAccountLocaleMismatch || isLocalOrderLocaleMismatch) {
      displayCountrySelectionNotification();
      resetOrder();
      void replace({ pathname, query: clearedQuery }, undefined, {
        shallow: true,
      });

      logger
        .withContext({
          extra: {
            isAccountLocaleMismatch,
            isLocalOrderLocaleMismatch,
            orderId: localOrderInfo?.orderId,
            orderLocale: localOrderInfo?.orderLocale,
          },
        })
        .info('Locale mismatch detected, resetting order');
    }
  }, [
    displayCountrySelectionNotification,
    locale,
    pathname,
    query,
    replace,
    resetOrder,
  ]);

  useEffect(() => {
    const checkOrderChannelMismatch = async (): Promise<void> => {
      const order = await loadLocalOrder();

      if (!order) {
        return;
      }

      const orderChannel = order.metadata?.sales_channel as string | undefined;
      const isChannelMismatch = areChannelsDifferent(channel, orderChannel);

      if (isChannelMismatch) {
        resetOrder();
        logger
          .withContext({
            extra: {
              channel,
              orderId: order.id,
              orderChannel,
            },
          })
          .info('Channel mismatch detected, resetting order');
      }
    };

    void checkOrderChannelMismatch().catch((err) => {
      // TODO: displayChannelMismatchNotification();
      logger.error(err, 'channel mismatch');
    });
  }, [channel, loadLocalOrder, resetOrder]);

  const handleCountryChange = async (code: string): Promise<void> => {
    const newCountry = countries.find((country) => country.code === code);
    const newLocale = `${newCountry.defaultLanguageCode}-${newCountry.code}`;

    resetOrder();
    await handleLocaleChange(newLocale);
    displayCountrySelectionNotification();
  };

  // sync line item promotions with prc parameters
  usePromotionPRCs(prices, isVATReversalFlagEnabled);

  const skipShopProps = {
    message: pageContent.haveAReaderMessage,
    actionTitle: pageContent.haveAReaderActionTitle,
    actionUrl: attachSearchToUrl(
      pageContent.haveAReaderRedirectLink,
      searchParams,
    ),
  };

  const showProductsOnOneLine = products.length <= MAX_PRODUCTS_PER_LINE;
  const hasProductsInCart = productsInCart.length > 0;

  const isDashboardShop = channel === 'dashboard';
  const hasPageSections = !!sections;
  const showSections = hasPageSections && !isDashboardShop;

  return (
    <>
      <Meta
        title={metaReference.metaTitle}
        description={metaReference.metaDescription}
        path={`/${locale}/product-selection`}
        locale={locale}
      />
      {pageContent.blackHeaderBar && (
        <BlackHeaderBar copy={pageContent.blackHeaderBar} />
      )}
      <StyledGrid>
        <ProductSelectionPageTitle
          title={pageContent.productListTitle}
          hasPageSections={hasPageSections}
        />
      </StyledGrid>

      <ProductGallery
        addToCartText={pageContent.addToCartText}
        digitalProducts={digitalProducts}
        isDiscountPriceEnabled={isDiscountPriceEnabled}
        isTTPOIFlagEnabled={isTTPOIFlagEnabled}
        isVATReversalFlagEnabled={isVATReversalFlagEnabled}
        prices={prices}
        products={products}
        productCardContent={productCardContent}
        purchaseLimitsBySkuCodes={purchaseLimitsBySkuCodes}
        taxMessage={taxMessage}
      />

      {showSections &&
        sections.map((section) => (
          <Section
            key={section.fields.name}
            sectionKey={section.fields.name}
            sectionFields={section.fields}
          />
        ))}

      <Footer
        {...skipShopProps}
        isProductSelectionPage
        footerContent={footerContent}
        hideCart={isDashboardShop || isBusinessAccountProposed}
        localeDropdownProps={{
          countries,
          onLocaleChange: handleLocaleChange,
          onCountryChange: handleCountryChange,
          currentLocale: locale,
          countryLabel: pageContent.countrySelectLabel,
          languageLabel: pageContent.languageSelectLabel,
        }}
      />

      <TransparentFeesAndFooter
        showProductsOnOneLine={showProductsOnOneLine}
        hasProductsInCart={hasProductsInCart}
      >
        <Cart
          {...cartContent}
          isLogoClickable={isLogoClickable}
          purchaseLimitsBySkuCodes={purchaseLimitsBySkuCodes}
          productsContentful={products}
          upsellProductsContentful={upsellProducts}
          marketProducts={marketProducts}
          defaultTaxRate={defaultTaxRate}
          isBusinessAccountBundleEnabled={isBusinessAccountBundleEnabled}
        />
      </TransparentFeesAndFooter>
    </>
  );
};

export const ProductSelection = withNotifications(ProductSelectionComponent);
